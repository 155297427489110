// Mobile navigation
const body = document.querySelector('body');
const navToggleBtn = document.querySelector('#tooggleNav');
const nav = document.querySelector('.nav');

function toggleMobileNav() {
  navToggleBtn.classList.toggle('mobile-nav-toggle--active');
  body.classList.toggle('fixed');
  nav.classList.toggle('nav--open');
}

if(navToggleBtn) {
  navToggleBtn.addEventListener('click', toggleMobileNav);
}

// Scroll to element
$('.scroll-link').click(function (event){
  event.preventDefault();
  if($(this).hasClass('nav__link')) {
    if($(this).parent('.nav__item').hasClass('active')) {
      return;
    } else {
      $('.nav__item').removeClass('active');
      $(this).parent('.nav__item').addClass('active');
    }
  } else {
    $('.nav__item').removeClass('active');
  }

  if($(this).closest('.nav').hasClass('nav--open')) {
    toggleMobileNav();
  }

  var headerHeight = $('.header').outerHeight();

  $('html, body').animate({
    scrollTop: $($(this).attr('href')).offset().top - headerHeight
  }, 600);
});

// Scroll header
$(window).scroll(function(){
  var $header = $('.header'),
      headerHeight = $header.outerHeight();
      scroll = $(window).scrollTop();

  if (scroll >= headerHeight) $header.addClass('scrolled');
  else $header.removeClass('scrolled');
});

// Sliders
function mainSlider(element, items, rows) {

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    prevArrow: element.next('.slider-arrows').find('.slider-prev-btn'),
    nextArrow: element.next('.slider-arrows').find('.slider-next-btn'),
    appendDots: element.next('.slider-arrows').find('.slider-dots'),
    rows: rows > 1 ? rows : 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: "unslick"
      }
    ]
  };

  if(element.length) {
    const slider = $(element).slick(settings);

    $(window).on('resize', function() {
      if( $(window).width() < 768 &&  !slider.hasClass('slick-initialized')) {
        $(element).slick(settings);
      }
    });
  }
}

mainSlider($('.work-stages__list'), $('.work-stages__item'));

$('.testimonials__list').slick({
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  prevArrow: $('.testimonials__list').next('.testimonials__nav').find('.slider-prev-btn'),
  nextArrow: $('.testimonials__list').next('.testimonials__nav').find('.slider-next-btn'),
  appendDots: $('.testimonials__list').next('.testimonials__nav').find('.slider-dots'),
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    }
  ]
});

// Popup
$('.open-popup-link').magnificPopup( {
  fixedContentPos: true,
  focus: 'input',
  autoFocusLast: false,
});

// Check input value
$('input:not([type="checkbox"])').on('focus', function() {
  $(this).closest('label').addClass('filled');
});

$('input:not([type="checkbox"]').on('blur', function() {
  if( $(this).val() ) {
    $(this).closest('label').addClass('filled');
  } else {
    $(this).closest('label').removeClass('filled');
    $(this).closest('.contact-form__item').removeClass('filled');
  }
});

document.addEventListener( 'wpcf7invalid', function( event ) {
  $(event.target).find('input:not([type="checkbox"]):not([type="hidden"])').each(function(){
    if($(this).val()) {
      $(this).closest('.contact-form__item').addClass('filled');
    }
  });
}, false );

document.addEventListener( 'wpcf7submit', function( event ) {
  $('.label').each(function() {
    $(this).removeClass('filled');
  });
  $(".wpcf7-form.sent").each(function() {
    setTimeout(function() {
      location.reload();
    },3000);
  });
  $('.wpcf7-response-output').delay(5000).fadeOut(800);
}, false );

// Disabled submit if not accept privacy
$('form .btn[type="submit"]').prop('disabled', true).addClass('btn--disabled');

$(document).on('click', 'input[type="checkbox"]', function(){
  if($(this).is(':checked')) {
    $(this).closest('form').find('.btn[type="submit"]').removeClass('btn--disabled').prop('disabled', false);
  } else {
    $(this).closest('form').find('.btn[type="submit"]').addClass('btn--disabled').prop('disabled', true);
  }
});

// Back to top
var $toTopLink = $('.to-top-link'),
    $hero = $('.hero');

$toTopLink.fadeOut();

function scrollToTop() {
  var windowScroll = $(window).scrollTop(),
      heroHeight = $hero.outerHeight();

  if (windowScroll >= heroHeight) $toTopLink.fadeIn('100');
  else $toTopLink.fadeOut('100');
}

$(document).ready(scrollToTop);
$(window).scroll(scrollToTop);